#accordion {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  /* background-image: url('https://i.imgur.com/Rls5q4N.jpg'); */
}

#accordion > :first-child {
  border-top: 5px solid white;
}

.closed-tab {
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: 20vh;
  width: 100vw;
  border: solid white;
  border-width: 2.5px 5px 2.5px 5px;
  transition: all 1s ease;
}

.open-tab > .tab-title {
  height: 0;
}

.closing-tab {
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: 20vh;
  width: 100vw;
  border: solid white;
  border-width: 2.5px 5px 2.5px 5px;
  transition: height 1s ease;
}

.closing-tab > .tab-title {
  height: 20vh;
}

.tab-title {
  overflow: hidden;
  height: 20vh;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  justify-items: center;
  color: white;
  text-shadow: 3px 3px 2px black;
  font-size: calc(1.3em + 3vw);
  font-family: 'Gelasio', serif;
  text-align: center;
  transition: all 1s;
}

#about-tab {
  background-color: #7d6fffA8;
}

#choreographer-tab {
  background-color: #ff6fbcA8;
}

#performance-tab {
  background-color: #6FC3FFA8;
}

#resume-tab {
  background-color: #FFF16FA8;
}

#contact-tab {
  background-color: #CD6FFFA8;
  border-bottom-width: 5px;
}

#about-tab > .tab-title > h1 {
  grid-column-start: 1;
  margin-left: 5px;
}

#choreographer-tab > .tab-title > h1 {
  grid-column-start: 2;
  margin: 0;
  /* width: 200%; */
  /* text-align: right; */
}

.performance-fancybox > .tab-title > h1 {
  grid-column-start: 3;
}

.edit-performance-btn {
  position: absolute;
}

#resume-tab > .tab-title > h1 {
  grid-column-start: 4;
}

#contact-tab > .tab-title > h1 {
  grid-column-start: 5;
  margin-right: 40px;
}

@media (hover: hover) and (pointer: fine) {
  .closed-tab:hover {
    z-index: 2;
    margin: -2vh 0;
    height: 24vh;
    box-shadow: 0 8px 4px black;
    cursor: pointer;
  }

  .closed-tab:hover > .tab-title {
    height: 24vh;
    font-size: calc(1.3em + 4vw);
    transition: all 1s
  }
  
  .closed-tab:hover > .performance-fancybox > .tab-title {
    height: 24vh;
    font-size: calc(1.3em + 4vw);
    transition: all 1s
  }
}

@media (max-width: 576px) {
  #contact-tab > .tab-title > h1 {
    margin-right: 5px;
  }
  
}
