#resume-bg {
  background-image: url('../../../public/images/katie-resume.jpg');
  background-size: cover;
  background-position: 70% 50%;
}

#resume {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  background-color: #FFF16FA8;
}

#resume > .line {
  background-color: black;
  width: 80vw;
  margin: 0 0 20px;
}

#resume-frame {
  display: grid;
  grid-template: repeat(3, 1fr) / repeat(3, 1fr);
  grid-template-areas:
    "top-left center top-right"
    ". center ."
    "bottom-left center bottom-right";
  height: 75vh;
  width: 75vw;
}
  
.corner-border {
  position: relative;
  z-index: 1;
  width: 10vw;
  height: 10vw;
  border: solid black;
  border-width: 5px 0 0 5px;
}

#corner-border-1 {
  grid-area: top-left;
}

#corner-border-2 {
  grid-area: top-right;
  position: relative;
  left: 100%;
  rotate: 90deg;
  transform: translateY(100%);
}

#corner-border-3 {
  grid-area: bottom-left;
  position: relative;
  top: 100%;
  transform: translateX(100%);
  rotate: -90deg;
}

#corner-border-4 {
  grid-area: bottom-right;
  position: relative;
  top: 100%;
  left: 100%;
  transform: translate(100%, 100%);
  rotate: 180deg;
}

#resume-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-inline: -1000px;
  grid-area: center;
  justify-self: center;
  align-self: center;
  height: 60vh;
  width: 60vw;
  z-index: 0;
}

#resume-container > div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

#resume-container > div > h3 {
  margin-block: 20px;
  font-size: calc(1.5em + 2vw);
  font-family: 'Gelasio', serif;
  color: black;
}

#resume-container > div > i {
  font-size: 5vw;
}

.view-pdf {
  margin-block: 20px;
  height: 6vw;
  width: 15vw;
  color: black;
  font-size: calc(0.3em + 1vw);
  font-family: 'Montserrat', sans-serif;
  border: 8px double black;
  border-radius: 100px;
  transition: all 1s;
}

@media (hover: hover) and (pointer: fine) {
  .view-pdf:hover {
    cursor: pointer;
    border-width: 5px;
  }
}

@media (max-width: 1024px) {
  #resume-container {
    grid-template: 1fr 1fr / none 
  }

  #resume-container > div {
    justify-content: space-between;
  }

  #resume-container > div > i {
    font-size: 10vw;
  }

  .view-pdf {
    height: 12vw;
    width: 30vw;
    font-size: calc(0.3em + 2.5vw);
  }
}

@media (max-width: 576px) {
  #resume-container > div > i {
    font-size: 13vw;
  }

  .view-pdf {
    height: 20vw;
    width: 38vw;
    font-size: calc(0.3em + 3vw);
  }
}

