#edit-performance {
  min-height: 100vh;
  background-color: grey;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

#edit-performance-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 30vh; */
  width: 100vw;
}

#edit-performance-header > h1 {
  font-size: 3em;
}

#performance-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#performance-form > * {
  margin: 20px;
}