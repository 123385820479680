#delete-album {
  display: flex;
  justify-content: center;
  width: 30vw;
  height: 45vh;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 1.5em;
}

#delete-album-message {
  display: grid;
  align-items: center;
  grid-template: 1fr 1fr / 1fr 1fr;
}

#delete-album-message > h1 {
  grid-column: span 2;
  align-self: end;
}

#delete-album-message > button {
  margin: 0 40px;
}