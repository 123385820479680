#contact-bg {
  background-image: url('../../../public/images/katie-contact2.jpg');
  background-size: cover;
  background-position: 85%;
}

#contact {
  position: relative;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  background-color: #CD6FFFA8;
}

#contact > .line {
  width: 80vw;
}

#contact-form {
  position: relative;
  top: 35%;
  transform: translateY(-40%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 60vw;
}

#contact-form > *:not(button) {
  width: 100%;
  border: none;
  font-family: 'Montserrat', sans-serif;
}

#contact-form > input {
  font-size: 1.5em;
}

#contact-form > textarea {
  font-size: 1.3em;
  height: 20vh;
}

#contact-error-msg {
  position: relative;
  top: 10%;
  color: white;
  font-size: 2em;
}

#contact-success-msg {
  position: relative;
  top: 18%;
  font-family: 'Gelasio', serif;
  font-size: 2em;
  color: white;
}

@media (max-width: 576px) {
  #contact > .line {
    width: 90vw;
  }

  #contact-form {
    width: 80vw;
  }
}


