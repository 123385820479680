.album-thumbnail {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 30vw;
  height: 45vh;
}

.edit-album-btn {
  position: absolute;
  top: 6.4vh;
  left: 0;
}

.delete-album-btn {
  position: absolute;
  top: 6.4vh;
  right: 0;
}

.album-thumbnail > h1 {
  color: white;
  font-size: calc(1em + 1.2vw);
  font-family: 'Gelasio', serif;
}

.fancybox-thumbnail {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  overflow: hidden;
  height: 20vw;
  width: 30vw;
  border: 2.5px solid white;
  border-radius: 20px;
  cursor: pointer;
}

.fancybox-thumbnail > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.album-subtitles {
  font-size: calc(1em + 0.5vw);
  font-family: 'Gelasio', serif;
  color: white;
}

.album-thumbnail > .line {
  width: 33vw;
}


@media (max-width: 1024px) {
  .album-thumbnail {
    width: 80vw;
  }
  
  .album-thumbnail > h1 {
    font-size: calc(1em + 3vw);
    font-family: 'Tenor Sans', sans-serif;
  }
  
  .fancybox-thumbnail {
    height: 46.66vw;
    width: 70vw;
  }
  
  .album-thumbnail > .line {
    width: 88vw;
  }
  
}
@media (max-width: 576px) {
  .fancybox-thumbnail {
    height: 80vw;
    width: 80vw;
  }
  
  .fancybox-thumbnail > img {
    object-fit: cover;
    object-position: center;
  }

}