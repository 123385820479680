.admin-button {
  height: 50px;
  width: 150px;
  border: 5px solid;
  border-radius: 100px;
  font-size: 1em;
  cursor: pointer;
}

.user-btn {
  height: 6vw;
  padding: 0 50px;
  background-color: white;
  color: black;
  font-size: calc(0.3em + 1vw);
  font-family: 'Montserrat', sans-serif;
  border: 8px double black;
  border-radius: 100px;
  transition: all 1s;
}

.success {
  border-color: white;
  background-color: rgb(9, 104, 0);
  color: white;
}

.warning {
  background-color: yellow;
}

.danger {
  background-color: red;
}

@media (hover: hover) and (pointer: fine) {
  .user-btn:hover {
    border-width: 5px;
  }
}

@media (max-width: 1024px) {

  .user-btn {
    height: 12vw;
    font-size: calc(0.3em + 2.5vw);
  }

}

@media (max-width: 576px) {

  .user-btn {
    height: 20vw;
    font-size: calc(0.3em + 4vw);
  }
}