#album-bg {
  position: relative;
  display: grid;
  clip-path: inset(0%);
  grid-template-columns: 1fr; /* Single column layout */
  z-index: 1;
}

#album-bg-img {
  visibility: hidden;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.album {
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  padding-bottom: 20px;
}

.album-thumbnails-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
}

#edit-order-btn {
  position: absolute;
  left: 20px;
}

.double-line {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  width: 100vw;
}

.double-line > .line {
  width: 40vw;
}

@media (hover: hover) and (pointer: fine) {
  .album {
    background-color: #ff6fbcA8;
  }

  #album-bg-img {
    visibility: visible;
  }
}

@media (max-width: 1024px) {
  .double-line {
    grid-template-columns: 1fr;
  }
  
  .double-line > .line {
    width: 90vw;
    height: 2.5px;
  }

  .album-thumbnails-container {
    grid-template-columns: 1fr;
  }
}
