.page-header {
  width: 100vw;
  height: 15vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  font-size: calc(1em + 5.3vw);
  font-family: 'Yeseva One', serif;
  color: white;
}

.page-header.black {
  color: black;
}

.page-header > h1 {
  grid-column: 2;
}

.page-header > i {
  position: relative;
  right: 30px;
  top: 0;
  justify-self: end;
  transition: all 1s;
  
}

@media (hover: hover) and (pointer: fine) {
  .page-header > i:hover {
    cursor: pointer;
    top: 10px;
    text-shadow: -6px -6px 5px black;
  }
}

@media (max-width: 576px) {
  .page-header > h1 {
    position: relative;
    left: -40px;
  }
  
}