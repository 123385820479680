#new-album {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.8);
}

#close-album-form-btn {
  position: absolute;
  top: 70px;
  right: 100px;
  background: none;
  border: none;
  cursor: pointer;
  color: red;
  font-size: 70px;
}

#new-album-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60vw;
  height: 60vh;
  border: 5px solid white;
  border-radius: 50px;
  background-color: rgb(58, 58, 58);
}

#album-form {
  display: grid;
  grid-template: repeat(6, 1fr) / 1fr 1fr;
  height: 70%;
  width: 80%;
  align-items: center;
}

#title-input {
  font-size: 2em;
  grid-column: span 2;
}

#album-form > label {
  margin: 0 20px;
  justify-self: end;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: white;
}

#album-form > input:not(.text-input) {
  color: white;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

#theater-input {
  color: black;
}

#album-submit-button {
  grid-column: span 2;
  justify-self: center;
  width: 30%;
  height: 50%;
  font-size: 1.2em;
}

@media (max-width: 576px) {
  #new-album-container {
    width: 80vw;
    height: 80vh;
  }
  
  #album-form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    width: 80%;
    align-items: center;
  }
  
  #title-input {
    width: 100%;
    font-size: 2em;
  }

  #album-form > label {
    font-size: 1em;
  }

  #album-form > input:not(#title-input) {
    width: 90%;
  }

  #album-submit-button {
    font-size: 1.5em;
    height: 10%;
    width: 70%;
  }
}