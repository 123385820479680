#landing-bg {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  background-image: url('../../../public/images/katie-landing.jpg');
  background-size: cover;
  /* background-position: 0 10%; */
  background-position: top;
  height: 100vh;
}

/* #landing-bg > * {
  position: relative;
  top: -10%;
} */

#title-container {
  position: relative;
  width: 100%;
  margin-top: 8%;
}

#landing-title {
  text-align: center;
  /* margin: 8% 0 0 0; */
  font-size: calc(3em + 5vw);
  font-family: 'Kalnia', serif;
  font-weight: 600;
  color: white;
}

#insta-link {
  position: absolute;
  right: 6%;
  top: 50%;
}

#insta-icon {
  color: white;
  font-size: 4vw;
  transition: all 1s;
}


#landing-bg > .line {
  width: 70vw;
  border-radius: 10px;
}

#landing-subtitle {
  margin: 10px;
  font-size: calc(1em + 1.8vw);
  font-family: 'Kalnia', serif;
  color: white;
}

#landing-bg > i {
  position: absolute;
  cursor: pointer;
  text-shadow: 5px -2px 2px black;
  bottom: 0;
  font-size: calc(4em + 4vw);
  color: white;
  rotate: 180deg;
  transition: all 1s;
}

@media (hover: hover) and (pointer: fine) {
  #insta-icon:hover {
    font-size: 5vw;
  }
  
  #landing-bg > i:hover {
    bottom: 10px;
    text-shadow: 8px -8px 2px black;
  }
}

@media (max-width: 1024px) {
  #landing-bg {
    /* height: 75vh; */
    /* -webkit-text-stroke: 1px black; */
    text-shadow: 2px 2px 4px black;
  }

  #title-container {
    position: static;
    margin: 60% 0 0 0;
  }

  #landing-bg > .line {
    width: 95vw;
    box-shadow: 2px 2px 4px black;
  }

  #insta-link {
    width: fit-content;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  #insta-icon {
    font-size: 10vw;
  }
  
}