#about {
  height: 100vh;
  width: 100vw;
  background-color: #F6A379;
}

#about-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 8fr;
  height: 100vh;
  background-image: url('../../../public/images/katie-about.jpeg');
  background-size: contain;
  background-repeat: no-repeat;
}

#about-container > h1 {
  position: relative;
  height: 10vh;
  width: 20px;
  left: 4vw;
  top: 5vh;
  font-size: calc(1em + 5vw);
  font-family: 'Kalnia', serif;
  color: white;
}

/* #about-right {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  height: 100vh;
  width: 100%;
} */

#about-container > i {
  position: relative;
  right: 30px;
  top: 20px;
  justify-self: end;
  align-self: center;
  font-size: calc(1em + 4vw);
  transition: all 1s;
  color: white;
}

#about-statement {
  grid-column: 2;
  position: relative;
  justify-self: center;
  align-self: center;
  left: 7vw;
  width: 32vw;
  font-size: calc(1em + 0.3vw);
  font-family: 'Lato', sans-serif;
  white-space: pre-wrap;
}

#edit-about-btn {
  position: absolute;
  top: -50px;
}

#about-text-input {
  font-size: calc(0.79em + 0.3vw);
  font-family: 'Lato', sans-serif;
  height: 80vh;
  width: 100%;
}

#update-about-btn {
  height: 5vh;
}

@media (hover: hover) and (pointer: fine) {
  #about-container > i:hover {
    position: relative;
    cursor: pointer;
    top: 10px;
    text-shadow: -6px -6px 5px black;
  }
}

@media (max-width: 1024px) {
  #about {
    background-color: #E5A991;
  }

  #about-container {
    grid-template-columns: none;
    grid-template-rows: 1fr 20fr;
    background-position: bottom;
    background-size: 120%;
  }

  #about-statement {
    top: 5vh;
    grid-column: span 2;
    justify-self: start;
    align-self: start;
    width: 90vw;
    font-size: 1.6vmax;
  }

}