.edit-album {
  min-height: 100vh;
  background-color: grey;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.cancel-edit-album-btn {
  position: absolute;
  right: 0;
  font-size: 1.4em;
}

.edit-album-form {
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template: 1fr 1fr / 1fr 1fr 1fr;
  height: 70vh;
  width: 100vw;
}

#edit-title-input {
  font-size: calc(1em + 4vw);
  height: 10vh;
  grid-column: 2;
}

.submit-album-update-btn {
  font-size: 1em;
}

.edit-subtitles {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  width: 100%;
}

.edit-subtitles > * {
  margin: 5px;
}

#photo-order-btn {
  position: relative;
  top: 120px;
}

.add-photos {
  /* position: relative;
  left: 40px; */
  display: flex;
  justify-content: center;
  width: 150%;
}

#add-photos-input {
  width: 200px;
}

.fancybox-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
}

.edit-thumbnail {
  margin: 30px;
}

.edit-thumbnail-img {
  overflow: hidden;
  height: 20vw;
  width: 30vw;
  cursor: pointer;
  margin-bottom: 20px;
}

.edit-thumbnail-img > img {
  width: 100%;
}
