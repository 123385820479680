#log-out {
  position: fixed;
  top: 0;
  z-index: 3;
}

#accordion-bg {
  position: fixed;
  height: 100%;
  width: 100%;
  object-fit: cover;
  top: 0;
  z-index: -1;
}

@media (max-width: 480px) {
  #accordion-bg {
    height: 950px;
    object-position: center;
  }
}
