.photo-thumbnail {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 30vw;
  height: 45vh;
}

.photo-thumbnail-img {
  overflow: hidden;
  height: 20vw;
  width: 20vw;
  /* margin: 10vh 0; */
}

.photo-thumbnail-img > img {
  height: 100%;
  object-position: 50% 50%;
}

.photo-thumbnail > .line {
  width: 23vw;
}

.order-input {
  position: absolute;
  height: 15vh;
  text-align: center;
  font-size: 6vmax;
  width: 8vw;
  background-color: rgba(255, 255, 255, 0.788);
}

@media (max-width: 1024px) {
  .photo-thumbnail {
    height: 30vh;
  }
}

@media (max-width: 576px) {
  .photo-thumbnail-img {
    height: 40vw;
    width: 40vw;
  }

  .photo-thumbnail > .line {
    width: 43vw;
  }
  
  .photo-thumbnail-img > img {
    height: 100%;
    width: auto;
  }
}